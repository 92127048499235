<template>
  <div class="topNav col-lg-12 col-md-12">
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <!-- <a class="navbar-brand" href="#">Brand</a> -->
          <!-- <img class="navbar-brand" src="../../../assets/image/banner_2.png" alt=""> -->

          <el-image
            :src="our_brand.logo"
            cover
            v-if="our_brand && our_brand.logo"
            @click="toHome"
          ></el-image>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <!-- <ul class="nav navbar-nav">
            <li class="active"><a href="#">Link <span class="sr-only">(current)</span></a></li>
            <li><a href="#">Link</a></li>
            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">Dropdown <span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li><a href="#">Action</a></li>
                <li><a href="#">Another action</a></li>
                <li><a href="#">Something else here</a></li>
                <li role="separator" class="divider"></li>
                <li><a href="#">Separated link</a></li>
                <li role="separator" class="divider"></li>
                <li><a href="#">One more separated link</a></li>
              </ul>
            </li>
          </ul> -->
          <!-- <form class="navbar-form navbar-left">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Search">
            </div>
            <button type="submit" class="btn btn-default">Submit</button>
          </form> -->
          <ul class="nav navbar-nav navbar-right col-lg-2">
            <!-- <li v-if="LangList.length">
              <div class="banner_right navbar-btn">
                <div class="language_box">
                  <div class="language">
                    <img
                      src="../../../assets/image/banner_3.png"
                      alt=""
                      v-clickoutside="outsideClose"
                      @click="show = !show"
                    />
                    <el-collapse-transition>
                      <el-card :class="['box-card', { show: show }]">
                        <div
                          class="text item"
                          v-for="(item, index) in LangList"
                          :key="index"
                        >
                          {{ item.language }}
                        </div>
                      </el-card>
                    </el-collapse-transition>
                  </div>
                </div>
              </div>
            </li> -->
            <li class="infolis">
              <div class="info" @click="toDetail">
                <span>Log In</span>
              </div>
            </li>

            <!-- <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                aria-expanded="false">Dropdown <span class="caret"></span></a>
              <ul class="dropdown-menu">
                <li><a href="#">Action</a></li>
                <li><a href="#">Another action</a></li>
                <li><a href="#">Something else here</a></li>
                <li role="separator" class="divider"></li>
                <li><a href="#">Separated link</a></li>
              </ul>
            </li> -->
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-fluid -->
    </nav>
  </div>
</template>

<script>
export default {
  name: "demo",
  // 组件参数 接收父组件数据
  // 局部注册组件
  components: {},
  // 组件状态值
  data() {
    return {
      show: false,
      Configlogo: "",
      LangList: [],
      our_brand: this.$store.state.configList.our_brand,
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  directives: {
    clickoutside: {
      bind(el, binding, vnode) {
        function documentHandler(e) {
          if (el.contains(e.target)) {
            return false;
          }
          if (binding.expression) {
            binding.value(e);
          }
        }

        function KeyUp(e) {
          if (e.keyCode == 27) {
            if (binding.expression) {
              binding.value(e);
            }
          }
        }
        el.__vueClickOutSize__ = documentHandler;
        el.__vueKeyup__ = KeyUp;

        document.addEventListener("keyup", KeyUp);
        document.addEventListener("click", documentHandler);
      },
      unbind(el, binding) {
        document.removeEventListener("click", el.__vueClickOutSize__);
        delete el.__vueClickOutSize__;

        document.removeEventListener("keyup", el.__vueKeyup__);
        delete el.__vueKeyup__;
      },
    },
  },
  // 组件实例创建完成，DOM未生成，
  created() {},
  mounted() {
    this.getWebsiteLangList();
  },
  // 组件方法
  methods: {
    getWebsiteLangList() {
      this.$http.getWebsiteLangList().then((res) => {
        if (res.status == 200) {
          this.LangList = res.data.data;
        }
      });
    },
    outsideClose() {
      this.show = false;
    },
    toDetail() {
      window.open("https://jci.cc/web/#/newsfeeds");
    },
    toHome() {
      this.$router.push("/home");
    },
  },
  //组件 DOM 已经更新
  updated() {},
  // 实例销毁之前调用。
  beforeDestroy() {},
  // Vue 实例销毁后调用,事件监听器会被移除
  destroyed() {},
};
</script>

<style scoped lang='less'>
.topNav {
  width: 100%;
  position: absolute;
  top: 30px;
  z-index: 99;
}

.navbar {
  position: relative;
  margin-bottom: 20px;
  border: 0;
}

.navbar-default {
  background-color: #a9b1a6;
  background-color: transparent;
  border-color: #e7e7e7;
  padding-left: 20px;
  padding-right: 20px;
}

.el-image {
  width: auto;
  height: auto;
  max-width: 140px;
  max-height: 50px;
  cursor: pointer;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 0;
  font-size: 18px;
  line-height: 20px;
  max-width: 150px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.banner_right {
  margin-right: 5%;

  .language {
    position: relative;

    img {
      cursor: pointer;
    }

    .box-card {
      position: absolute;
      right: 0;
      top: 50px;
      background: #4266b0;
      border: 1px solid #4266b0;
      color: #fff;
      line-height: 26px;
      display: none;
      width: 130px;

      .el-card__body {
        padding: 10px 20px;
      }

      .item {
        cursor: pointer;
      }
    }

    .show {
      display: block;
    }
  }
}

.info {
  width: 120px;
  height: 50px;
  line-height: 50px;
  opacity: 1;
  background: #ffffff;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
}

.infolis {
  margin-left: 15%;
}
</style>