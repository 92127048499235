<template>
  <div class="pageFooter col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="vessel">
      <div class="left col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="contact_info">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h3>CONTACT US:</h3>
        </div>
        <br />
        <ul class="col-lg-12 col-md-12 col-sm-12 col-xs-12 Contact my_ul">
          <li>{{contact_info.office_address }}</li>
          <li v-for="(item, index) in contact_info.contact_info" :key="index">
            <span v-if="index<2">{{ item.content }} {{ item.note }}</span>
            <p v-else>{{ item.content }}<a :href="`mailto:${item.note}`">{{ item.note }}</a></p>
          </li>
        </ul>
      </div>
      <div class="left col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="contact_info">
        <div class="col-lg-12 col-md-12">
          <h3>CONNECT</h3>
        </div>
        <br />
        <ul class="col-lg-12 col-md-12 Contact Contact_connect">
          <li v-for="(item, index) in contact_info.connect" :key="index">
            <a @click="toDetailPage(item)">{{item.content}}</a>
          </li>
        </ul>
      </div>
      <div class="right col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="col-lg-6 col-md-6">
          <h3>DOWNLOAD JCI APP</h3>
          <div class="apps">
            <a :href="item.link" v-for="(item, index) in contact_info.download" :key="index">
              <img :src="item.icon" alt="" v-if="item.icon" />
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-md-6" v-if="contact_info&&contact_info.media&&contact_info.media.length">
          <h3>MEDIA:</h3>
          <div class="terrace">
            <a :href="item.link" v-for="(item, index) in contact_info.media" :key="index">
              <div class="icon-img">
                <img :src="item.icon" alt="" v-if="item.icon" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'demo',
    // 局部注册组件
    components: {},
    props: {
      type: {
        type: Number,
        default: 2
      }
    },
    // 组件状态值
    data() {
      return {
        contact_info: this.$store.state.configList.contact_info
      }
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件实例创建完成，DOM未生成，
    created() { },
    mounted() {

    },
    // 组件方法
    methods: {
      toDetailPage(item) {
        // if (this.type == 1) {
        //   if (item.note.includes('senate')) {
        //     this.$router.push('/senate')
        //   } else if (item.note.includes('foundation')) {
        //     this.$router.push('/foundation')
        //   } else if (item.note.includes('alumni')) {
        //     this.$router.push('/alumni')
        //   }
        // } else {
        //   if (item.note.includes('senate')) {
        //     this.$router.replace('/senate')
        //   } else if (item.note.includes('foundation')) {
        //     this.$router.replace('/foundation')
        //   } else if (item.note.includes('alumni')) {
        //     this.$router.replace('/alumni')
        //   }
        // }
        window.open(item.note, '_self')
      }
    },
    //组件 DOM 已经更新
    updated() { },
    // 实例销毁之前调用。
    beforeDestroy() { },
    // Vue 实例销毁后调用,事件监听器会被移除
    destroyed() { }
  }
</script>

<style scoped lang='less'>
  .pageFooter {
    min-height: 460px;
    background-color: #4266b0;

    .vessel {
      text-align: left;
      color: #ffffff;
      line-height: 30px;

      h3 {
        margin-bottom: 15px;
      }

      .Contact {
        font-size: 16px;
      }

      .Contact_connect li {
        display: flex;
        flex-direction: column;
      }

      .my_ul {
        li:nth-child(3) {
          margin-bottom: 38px;
        }
      }

      a {
        color: #edbe38;
      }

      .apps {
        display: flex;
        flex-direction: column;
        width: 170px;
        margin-top: 35px;

        img {
          width: 100%;
          height: 50px;
          margin-bottom: 40px;
          cursor: pointer;
        }
      }

      .bt-yellow {
        width: 150px;
        height: 50px;
        line-height: 50px;
        margin-top: 20px;
        text-align: center;
        opacity: 1;
        background: #edbe38;
        border-radius: 5px;
      }

      .right {
        .terrace {
          display: flex;
          align-items: center;
          flex-flow: wrap;

          .icon-img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
            margin-bottom: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 30px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .pageFooter {
      padding: 70px 180px 70px 180px;
    }
  }

  @media only screen and (min-width: 992px) {
    .pageFooter {
      padding: 70px 70px 70px 70px;
    }
  }

  @media only screen and (min-width: 768px) {
    .pageFooter {
      padding: 50px;
    }
  }
</style>